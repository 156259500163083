import { CostCenterCreateType } from "./CostCenterCreateType";
import { initCostCenterCreateType } from "./CostCenterCreateType";

/**
 * Type para atualizar
 *
 * @typedef {(
 *   CostCenterCreateType
 * )} CostCenterUpdateType
 */
export const CostCenterUpdateType = {};

/**
 * Inicializar CostCenterUpdateType
 *
 * @returns {CostCenterUpdateType}
 */
export const initCostCenterUpdateType = () => {
  return {
    ...initCostCenterCreateType(),
  }
};

