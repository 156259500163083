import store from "../../../../shared/libraries/store";
import { SearchBarFilterType } from "../../../../components/AppGlobal";
import { 
  CostCenterListFilterType, 
  CostCenterListPagerType, 
  CostCenterViewType, 
  initCostCenterListPagerType 
} from "../types";
import { costCenterStateKeys } from "./costCenterStoreStateKeys";

/** @returns {?CostCenterViewType} */
const getItem = () => store.getState(costCenterStateKeys.ITEM);

/** @returns {CostCenterListPagerType} */
const getListed = () => store.getState(costCenterStateKeys.LISTED) ?? initCostCenterListPagerType();

/** @returns {boolean} */
const getIsListing = () => store.getState(costCenterStateKeys.IS_LISTING) ?? false;

/** @returns {boolean} */
const getIsFinding = () => store.getState(costCenterStateKeys.IS_FINDING) ?? false;

/** @returns {boolean} */
const getIsSaving = () => store.getState(costCenterStateKeys.IS_SAVING) ?? false;

/** @returns {boolean} */
const getIsRemoving = () => store.getState(costCenterStateKeys.IS_REMOVING) ?? false;

/** 
 * @returns {{ 
 *   searchBarFilter: SearchBarFilterType, 
 *   filter: CostCenterListFilterType
 * }}
*/
const getPageState = () => store.getState(costCenterStateKeys.PAGE_STATES);

export default {
  getItem,
  getListed,
  getIsListing,
  getIsFinding,
  getIsSaving,
  getIsRemoving,
  getPageState,
};
