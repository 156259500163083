/** 
 * Definição das chaves únicas usadas para armazenar o estado.
 * 
 * Cada chave deve ser única em todo o projeto. 
 * Não deve haver duplicação de chaves em nenhum lugar do código.
 */
export const costCenterStateKeys = Object.freeze({
  ITEM: "COST_CENTER_ITEM",
  LISTED: "COST_CENTER_LISTED",
  IS_LISTING: "COST_CENTER_IS_LISTING",
  IS_FINDING: "COST_CENTER_IS_FINDING",
  IS_SAVING: "COST_CENTER_IS_SAVING",
  IS_REMOVING: "COST_CENTER_IS_REMOVING",
  PAGE_STATES: "COST_CENTER_PAGE_STATE",
});
