import costCenterStoreActions from './costCenterStoreActions';
import costCenterStoreGetters from './costCenterStoreGetters';
import costCenterStoreMutations from './costCenterStoreMutations';
import { costCenterStateKeys } from "./costCenterStoreStateKeys";

const costCenterStore = {
  ...costCenterStoreActions,
  ...costCenterStoreGetters,
  ...costCenterStoreMutations,
  costCenterStateKeys,
};

export default costCenterStore;