import store from "../../../../shared/libraries/store";
import { costCenterStateKeys } from "./costCenterStoreStateKeys";
import { 
  CostCenterListFilterType, 
  CostCenterListPagerType, 
  CostCenterViewType } from "../types";
import { SearchBarFilterType } from "../../../../components/AppGlobal";

/**
 * @param {CostCenterViewType} item
 * @returns {CostCenterViewType}
 */
const setItem = (item) => store.commit(costCenterStateKeys.ITEM, item);

/**
 * @param {CostCenterListPagerType} listed
 * @returns {CostCenterListPagerType}
 */
const setListed = (listed) => store.commit(costCenterStateKeys.LISTED, listed);

/**
 * @param {boolean} isListing
 * @returns {boolean}
 */
const setIsListing = (isListing) => store.commit(costCenterStateKeys.IS_LISTING, isListing);

/**
 * @param {boolean} isFinding
 * @returns {boolean}
 */
const setIsFinding = (isFinding) => store.commit(costCenterStateKeys.IS_FINDING, isFinding);

/**
 * @param {boolean} isSaving
 * @returns {boolean}
 */
const setIsSaving = (isSaving) => store.commit(costCenterStateKeys.IS_SAVING, isSaving);

/**
 * @param {boolean} isRemoving
 * @returns {boolean}
 */
const setIsRemoving = (isRemoving) => store.commit(costCenterStateKeys.IS_REMOVING, isRemoving);

/**
 * @param {CostCenterViewType} created
 * @returns {CostCenterViewType}
 */
const setNewItemInList = (created) => store.commitNewItemToList(costCenterStateKeys, created);

/**
 * @param {CostCenterListPagerType} listed
 * @returns {CostCenterListPagerType}
 */
const setListMerge = (listed) => store.commitListMerge(costCenterStateKeys, listed);

/**
 * @param {number|string} id
 */
const setRemovedItem = (id) => store.commitRemovedItem(costCenterStateKeys, id);

/**
 * @param {CostCenterViewType} updated
 * @returns {CostCenterViewType}
 */
const setUpdatedItem = (updated) => store.commitUpdatedItem(costCenterStateKeys, updated);

/** 
 * @param {{ 
*   searchBarFilter: SearchBarFilterType, 
*   filter: CostCenterListFilterType
* }} filters
*/
const setPageState = (filters) => store.commit(costCenterStateKeys.PAGE_STATES, filters);

/** Resetar chaves de estado */
const resetStates = () => {
  store.resetStates(Object.values(costCenterStateKeys));
}

export default {
  setItem,
  setListed,
  setIsListing,
  setIsFinding,
  setIsSaving,
  setIsRemoving,
  setNewItemInList,
  setListMerge,
  setRemovedItem,
  setUpdatedItem,
  setPageState,
  resetStates,
};
