import { BaseListFilterType, initBaseListFilterType } from "../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} CostCenterListFilterExtType
 * @property {boolean|null} status - false-Inativo, true-Ativo, null-Todos
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & CostCenterListFilterExtType)} CostCenterListFilterType
 */
export const CostCenterListFilterType = {};

/**
 * Inicializar CostCenterListFilterType
 *
 * @returns {CostCenterListFilterType}
 */
export const initCostCenterListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    order_by: [{ column: "cost_centers.id" }],
    custom_search: {
      columns: ["cost_centers.name"],
      values: [],
    },
    status: null,
  };
}
