import CostCenterListPage from "../views/list/CostCenterListPage.vue";

export default [
  {
    path: '/configuration/general/cost-centers',
    name: 'configuration.general.cost-center.view',
    component: CostCenterListPage,
    meta: {
      title: 'Configurações',
      description: 'Centro de Custo',
      icon: 'fa fa-home',
      breadcrumbs: [
        {title: 'Dashboard', name: 'configuration.general.cost-center.dashboard.index'},
        {title: 'Centro de Custo', name: null},
      ]
    },
  },
];
