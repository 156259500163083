import { PagerType, initPagerType } from "../../../../shared/types";
import { CostCenterListType } from "./CostCenterListType";

/**
 * Type para retorno paginado da listagem
 *
 * @typedef {(
 *   PagerType &
 *   { items: Array<CostCenterListType> }
 * )} CostCenterListPagerType
 */
export const CostCenterListPagerType = {};

/**
 * Inicializar CostCenterListPagerType
 * 
 * @returns {CostCenterListPagerType}
 */
export const initCostCenterListPagerType = () => {
  return {
    ...initPagerType(),
    items: [],
  }
};

