export { default as costCenterCreateService } from "./costCenterCreateService";
export { default as costCenterDeleteService } from "./costCenterDeleteService";
export { default as costCenterFindService } from "./costCenterFindService";
export { default as costCenterListService } from "./costCenterListService";
export { default as costCenterUpdateService } from "./costCenterUpdateService";
export { default as costCenterUpdateStatusService } from "./costCenterUpdateStatusService";

/** Urls para acesso externo */
export const costCenterUrls = Object.freeze({
  BASE: "/configuration/cost-centers",
});