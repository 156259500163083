const keys = Object.freeze({
  ALL: null,
  INACTIVE: 0,
  ACTIVE: 1,
});

const getTitle = (value) => {
  switch (value) {
    case keys.ALL: return 'Todos';
    case keys.INACTIVE: return 'Inativo';
    case keys.ACTIVE: return 'Ativo';
    default: return "";
  }
};

export default {
  keys,
  getTitle,
};
