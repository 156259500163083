import { 
  CostCenterCreateType, 
  CostCenterListFilterType, 
  CostCenterListPagerType, 
  CostCenterUpdateType, 
  CostCenterViewType 
} from "../types";
import { 
  costCenterCreateService, 
  costCenterDeleteService, 
  costCenterFindService, 
  costCenterListService, 
  costCenterUpdateService, 
  costCenterUpdateStatusService
} from "../services";
import mutations from "./costCenterStoreMutations";
import getters from "./costCenterStoreGetters";
import { 
  createResource, 
  findResource, 
  listResources, 
  removeResource, 
  updateResource } from "../../../../shared/store";

/**
 * Incluir
 *
 * @param {CostCenterCreateType} data
 * @returns {Promise<CostCenterViewType>}
 */
const create = async (data) => await createResource(data, mutations, costCenterCreateService);

/**
 * Localizar por ID
 *
 * @param {number|string} id
 * @returns {Promise<?CostCenterViewType>}
 */
const find = async (id) => await findResource(id, mutations, costCenterFindService);

/**
 * Listar
 *
 * @param {CostCenterListFilterType} filter - Filtro de listagem
 * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
 * @returns {Promise<?CostCenterListPagerType>} - Retorno paginado
 */
const list = async (filter, isAccumulateItems = false) => {
  return await listResources(filter, isAccumulateItems, mutations, getters, costCenterListService);
};

/**
 * Deletar por ID
 *
 * @param {number} id
 * @returns {Promise<void>}
 */
const remove = async (id) => await removeResource(id, mutations, costCenterDeleteService);

/**
 * Atualizar por ID
 *
 * @param {CostCenterUpdateType} data
 * @param {number} id
 * @returns {Promise<CostCenterViewType>}
 */
const update = async (data, id) => await updateResource(data, id, mutations, costCenterUpdateService);

/**
 * Atualizar por ID
 *
 * @param {0|1|null} status
 * @param {number} id
 * @returns {Promise<CostCenterViewType>}
 */
const updateStatus = async (status, id) => await updateResource(status, id, mutations, costCenterUpdateStatusService);

export default {
  create,
  find,
  list,
  remove,
  update,
  updateStatus
};
