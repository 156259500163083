import { costCenterUrls } from '.';
import httpClient from '../../../../shared/libraries/httpClient';
import { handleUnexpectedStatusCode } from '../../../../shared/services';
import { HttpStatusEnum } from '../../../../shared/enums';
import { CostCenterUpdateType, CostCenterViewType } from '../types';

/**
 * Atualizar por ID
 * 
 * @param {CostCenterUpdateType} data
 * @param {number} id
 * @returns {Promise<CostCenterViewType>}
 */
const execute = async (data, id) => {
  const url = `${costCenterUrls.BASE}/${id}`;
  const res = await httpClient.put(url, data);

  switch (res.status) {
    case HttpStatusEnum.HTTP_OK: return res.data.data;  
    default: handleUnexpectedStatusCode(res);
  }
};

export default { execute };