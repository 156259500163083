import { costCenterUrls } from ".";
import httpClient from "../../../../shared/libraries/httpClient";
import { handleUnexpectedStatusCode } from "../../../../shared/services";
import { HttpStatusEnum } from "../../../../shared/enums";
import { CostCenterViewType } from "../types";

/**
 * Atualizar por ID
 *
 * @param {0|1|null} status
 * @param {number} id
 * @returns {Promise<CostCenterViewType>}
 */
const execute = async (status, id) => {
  const url = `${costCenterUrls.BASE}/${id}/status/${status}`;
  const res = await httpClient.put(url);

  switch (res.status) {
    case HttpStatusEnum.HTTP_OK:
      return res.data.data;
    default:
      handleUnexpectedStatusCode(res);
  }
};

export default { execute };
