<template>
  <div>
    <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
      <form
        @submit.prevent="handleSubmit(onSubmitClick)"
        class="needs-validation"
        autocomplete="off"
      >
        <div class="container-fluid">
          <div class="row">
            <div class="col-12" style="display: flex; align-items: center;">
              <img
                src="/img/icons/icons8/ios/info-squared_warning.png"
                width="20"
              />
              <h4 class="m-0 mx-3" style="font-size: 16px; font-weight: 500; color: #2B2D32;">
                Identificação
              </h4>
              <hr class="ml-3" style="width: 100%" />
            </div>
          </div>
          <div class="form-group">
            <div class="form-group row m-0 p-0">
              <div class="col-md-5 my-1 px-0">
                <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                  Status
                </div>
              </div>
                <div v-if="formData.status === true" class="col-md-7 mb-2 px-0">
                  <span class="d-flex float-right align-items-center status-button-success" @click="formData.status = false;">
                    <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                    Ativo
                  </span>
                </div>
                <div v-if="formData.status === false" class="col-md-7 mb-2 px-0">
                  <span class="d-flex float-right align-items-center status-button-danger" @click="formData.status = true;">
                    <img src="/img/icons/icons8/ios/shutdown--v1_danger.png" alt="shutdown--v1_danger" width="12px">
                    Inativo
                  </span>
                </div>
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-6 mt-1">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Nome
              </h4>
            </div>
            <div class="col-6">
              <validation-provider rules="required" v-slot="{ errors }">
                <base-input input-group-classes="input-group-sm">
                  <input
                    v-model="formData.name"
                    type="text"
                    class="form-control form-control-sm"
                    :class="errors[0] ? 'is-invalid' : 'is-valid'"
                  />
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="modal-footer px-0">
                <div class="d-flex pr-2" style="gap: 18px;">
                  <div class="d-flex align-items-center button-cancel" style="gap: 5px;" @click="onCancelClick">
                    <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px">
                    <span style="font-weight: 500;">
                      Cancelar
                    </span>
                  </div>
                  <div class="d-flex align-items-center button-save" style="gap: 5px;" @click.prevent="onSubmitClick">
                    <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="16px">
                    <span style="font-weight: 500;">
                      Salvar
                    </span>
                  </div>
                </div>
              </div>
          <!-- <div class="row mt-5 mb-4">
            <div class="col-12 text-right">
              <base-button
                @click="onCancelClick"
                type="danger"
                size="sm"
                style="width: 150px"
              >
                <div style="display: inline-flex; align-items: center">
                  <img src="/img/icons/cancel-white.png" width="20" />
                  <i class="m-0 ml-1" style="font-style: normal"> Cancelar </i>
                </div>
              </base-button>
              <base-button
                type="success"
                size="sm"
                style="width: 150px"
                native-type="submit"
                :disabled="invalid"
                :loading="isSaving"
              >
                <div style="display: inline-flex; align-items: center">
                  <img src="/img/icons/save.png" width="20" />
                  <i class="m-0 ml-1" style="font-style: normal"> Salvar </i>
                </div>
              </base-button>
            </div>
          </div> -->
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, computed } from "vue";
import { CostCenterCreateType, CostCenterUpdateType } from "../../types";
import costCenterStore from "../../store";
import { ValidationObserver } from "vee-validate";

/**
 * Propriedades para o componente
 * @typedef {Object} Props
 * @property {CostCenterCreateType|CostCenterUpdateType} formData
 */
/** @type {Props} */
const props = defineProps({
  formData: {
    type: Object,
    required: true,
  },
});

/** Eventos emitidos */
const emit = defineEmits([
  "onCancelClick", 
  "onSubmitClick"
]);

/** Store Getters */
const isSaving = computed(() => costCenterStore.getIsSaving());

function onCancelClick() {
  emit("onCancelClick");
}

function onSubmitClick() {
  emit("onSubmitClick");
}
</script>

<style scoped>
.status-button-success {
  background-color: #F2F7F3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #149E57;
  font-weight: 400;
  padding: 4px 40px;
}
.status-button-danger {
  background-color: #F4C7C3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #DB4539;
  font-weight: 400;
  padding: 4px 34px
}
.button-cancel {
  cursor: pointer;
  border: 0.5px solid #DB4539;
  border-radius: 8px;
  color: #DB4539;
  padding: 4px 20px;
}
.button-save {
  cursor: pointer;
  border: 0.5px solid #149E57;
  border-radius: 8px;
  color: #149E57;
  padding: 4px 30px;
}
.button-save:hover {
  background: #149E57;
  color: white;
}
.button-save:hover img {
  filter: brightness(0) invert(1);
}
.button-cancel:hover {
  background: #DB4539;
  color: white;
}
.button-cancel:hover img {
  filter: brightness(0) invert(1);
}
</style>
