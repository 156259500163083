/**
 * Type para incluir
 *
 * @typedef {Object} CostCenterCreateType
 * @property {?number} id
 * @property {string} name
 * @property {boolean} status - [false-INATIVO, true-ATIVO]
 * @property {string} observation
 */
export const CostCenterCreateType = {};

/**
 * Inicializar CostCenterCreateType
 * 
 * @returns {CostCenterCreateType}
 */
export const initCostCenterCreateType = () => {
  return {
    name: '',
    status: true,
    observation: '',
  }
};
